import React from 'react'
import { useForm } from 'react-hook-form'
// import Req from './Required'


const Form = ({ onSubmit, isClubNautico, isGarage, loading, policy }) => {
  const {
    handleSubmit,
    register,
    errors,
  } = useForm()

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      id="calcular-precio-form"
    >

      <h3 className="font-weight-bold">Datos de la embarcación</h3>
      <div className="row">
        <div className="col">
          <div className="form-group">
            <label htmlFor="marca">Marca</label>
            <input
              type="text"
              min="0"
              className={`form-control ${errors.marca && 'is-invalid'}`}
              id="marca"
              name="marca"
              ref={register({ required: true })}
            />
          </div>
        </div>

        <div className="col">
          <div className="form-group">
            <label htmlFor="modelo">Modelo</label>
            <input
              type="text"
              min="0"
              className={`form-control ${errors.modelo && 'is-invalid'}`}
              id="modelo"
              name="modelo"
              ref={register({ required: true })}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <div className="form-group">
            <label htmlFor="matricula">Matrícula o Número de inscripción</label>
            <input
              type="text"
              min="0"
              className={`form-control ${errors.matricula && 'is-invalid'}`}
              id="matricula"
              name="matricula"
              ref={register({ required: true })}
            />
          </div>
        </div>

        <div className="col">
          <div className="form-group">
            <label htmlFor="nombreEmbarcacion">
              Nombre de la embarcación
              <span className="text-muted"> (si dispone)</span>
            </label>
            <input
              type="text"
              min="0"
              className={`form-control ${errors.nombreEmbarcacion && 'is-invalid'}`}
              id="nombreEmbarcacion"
              name="nombreEmbarcacion"
              ref={register({ required: false })}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <div className="form-group">
            <label htmlFor="estanciaHabitual">Lugar amarre o estancia habitual de la embarcación</label>
            <input
              type="text"
              min="0"
              className={`form-control ${errors.estanciaHabitual && 'is-invalid'}`}
              id="estanciaHabitual"
              name="estanciaHabitual"
              ref={register({ required: true })}
            />
            <small className="form-text text-muted">
              Ejemplo: En el garaje de mi residecia
            </small>
            <small className="form-text text-muted">
              Ejemplo: Marina Club Nautico Barcelona
            </small>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col">
          {isClubNautico &&
            <div className="form-group">
              <label htmlFor="nombreClubNautico">Nombre del puerto deportivo/club náutico</label>
              <input
                type="text"
                min="0"
                className={`form-control ${errors.nombreClubNautico && 'is-invalid'}`}
                id="nombreClubNautico"
                name="nombreClubNautico"
                ref={register({ required: true })}
              />
            </div>
          }
          {isGarage &&
            <div className="form-group">
              <label htmlFor="estanciaHabitual">Lugar de la estancia habitual de la embarcación</label>
              <input
                type="text"
                min="0"
                className={`form-control ${errors.estanciaHabitual && 'is-invalid'}`}
                id="estanciaHabitual"
                name="estanciaHabitual"
                ref={register({ required: true })}
              />
            </div>
          }
        </div>

        <div className="col">
        </div>
      </div>

      <hr className="m-5" />

      <h3 className="font-weight-bold">Datos del tomador</h3>

      <div className="row">
        <div className="col">
          <div className="form-group">
            <label htmlFor="nombre">Nombre y apellidos</label>
            <input
              className={`form-control ${errors.nombre && 'is-invalid'}`}
              type="text"
              id="nombre"
              name="nombre"
              ref={register({ required: true })}
              defaultValue={policy.nombre}
            />
          </div>
        </div>
        <div className="col">
          <div className="form-group">
            <label htmlFor="nombre">DNI/Pasaporte</label>
            <input
              className={`form-control ${errors.documento && 'is-invalid'}`}
              type="text"
              id="documento"
              name="documento"
              ref={register({ required: true })}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              className={`form-control ${errors.email && 'is-invalid'}`}
              type="text"
              id="email"
              name="email"
              ref={register({
                required: true,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: 'El formato del email es inválido',
                },
              })}
              defaultValue={policy.email}
            />
          </div>
        </div>
        <div className="col">
          <div className="form-group">
            <label htmlFor="telefono">
              Num. teléfono
            </label>
            <input
              type="number"
              min="0"
              className={`form-control ${errors.telefono && 'is-invalid'}`}
              id="telefono"
              name="telefono"
              ref={register({ required: true })}
              defaultValue={policy.telefono}
            />
          </div>
          {errors.telefono && errors.telefono.required}
        </div>
      </div>

      <div className="row">
        <div className="col">
          <div className="form-group">
            <label htmlFor="fechaNacimiento">Fecha nacimiento</label>
            <input
              className={`form-control ${errors.fechaNacimiento && 'is-invalid'}`}
              type="text"
              id="fechaNacimiento"
              name="fechaNacimiento"
              ref={register({ required: true })}
            />
            <small className="form-text text-muted">
              Ejemplo: 01/02/1975
            </small>
          </div>
        </div>
        <div className="col">
        </div>
      </div>

      <div className="row">
        <div className="col">
          <div className="form-group">
            <label htmlFor="direccionPostal">Dirección postal <span className="text-muted">(completa)</span></label>
            <input
              className={`form-control ${errors.direccionPostal && 'is-invalid'}`}
              type="text"
              id="direccionPostal"
              name="direccionPostal"
              ref={register({ required: true })}
            />
            <small className="form-text text-muted">
              Ejemplo: Calle Sevilla, n34, 3A, Villaluenga de la Sagra (Toledo), 45520, España
            </small>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <div className="form-group">
            <label htmlFor="iban">IBAN</label>
            <input
              className={`form-control ${errors.iban && 'is-invalid'}`}
              type="text"
              id="iban"
              name="iban"
              ref={register({ required: true })}
            />
            <small className="form-text text-muted">
              Ejemplo: ES00 1234 1234 1234 1234 1234
            </small>
          </div>
        </div>
      </div>

      <hr className="m-5" />

      <div className="form-group">
        <label htmlFor="comentarios">
          Comentarios
          <span className="text-muted"> (opcional)</span>
        </label>
        <textarea
          className="form-control"
          id="comentarios"
          name="comentarios"
          rows="3"
          ref={register()}
        />
      </div>

      <hr className="m-5" />

      <button
        type="submit"
        className={`btn btn-lg btn-primary ${loading && 'disabled'}`}
      >
        {loading &&
          <>
            <span className="spinner-border text-light" role="status">
              <span className="sr-only">Loading...</span>
            </span>
            {' '}
          </>
        }
        CONTRATAR
      </button>
    </form>
  )
}

export default Form
