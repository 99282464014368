import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'

class EnBreveModal extends Component {
  render () {
    const { shown } = this.props

    return (
      <Modal show={shown}>
        <Modal.Body className="text-center">
          <div className="text-left">
            <h3>
              Perfecto! En breve nos pondremos en contacto contigo
            </h3>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Link to="/calcular-precio">
            <Button
              variant="secondary"
              size="lg"
            >
              Calcular otro seguro
            </Button>
          </Link>
          <Link to="/">
            <Button
              variant="secondary"
              size="lg"
            >
              Cerrar
            </Button>
          </Link>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default EnBreveModal
