import React, { PureComponent } from 'react'

import Form from './Form/container'
import EnBreveModal from './EnBreveModal'
import OfertaContratada from './OfertaContratada/container'

import SegurosApi from './../../../services/seguros-api'


class CalcularPrecio extends PureComponent {
  state = {
    showEnBreveModal: false,
  }

  handleOnSubmit = (formData) => {
    const { policy, selectedOffer } = this.props
    const data = {
      ...policy,
      ...formData,
      selectedOffer,
    }

    SegurosApi.contratarSeguro(data).then(() => {

    }).catch(() => {
      SegurosApi.contratarSeguro(data).then(() => {

      }).catch(() => {
        alert('Algo no funciono')
      })
    })

    this.setState({
      showEnBreveModal: true,
    })
  }

  render () {
    const { showEnBreveModal } = this.state

    return (
      <div>
        <EnBreveModal
          shown={showEnBreveModal}
        />

        <div className="container pt-5 pb-5">

          <div className="row">
            <div className="col-md"></div>
            <div className="col-md-10">
              <OfertaContratada />

              <div className="card card-calcular-precio mt-5">
                <div className="card-header">

                    Datos necesarios para contratar la póliza:

                </div>
                <div className="card-body">
                  <Form
                    type={new URLSearchParams(window.location.search).get('tipo')}
                    onSubmit={this.handleOnSubmit}
                  />
                </div>
              </div>
            </div>
            <div className="col-md"></div>
          </div>

        </div>
      </div>
    )
  }
}

export default CalcularPrecio
