import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'


class Concept extends Component {
  state = {
    infoModalShown: false,
  }

  handleOnClick = () => {
    this.setState({
      infoModalShown: true,
    })
  }

  handleInfoModalClose = () => {
    this.setState({
      infoModalShown: false,
    })
  }

  render () {
    const { concept, small } = this.props
    const { infoModalShown } = this.state

    return (
      <>
        <Modal
          show={infoModalShown}
          backdrop="static"
          onHide={this.handleInfoModalClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {(concept.indexOf('RC O') !== -1) && (
                <>
                  Responsabilidad civil obligatoria (RD607/99)
                </>
              )}

              {(concept.indexOf('RC O') === -1) && (
                <>
                  {concept}
                </>
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {(concept.indexOf('RC O') !== -1) && (
              <>
                <p>
                  La contratación del seguro de Responsabilidad Civil garantiza el pago de las indemnizaciones correspondientes por daños personales y materiales causados a terceros:<br />
                  muerte, invalidez, lesiones corporales, daños materiales y las pérdidas económicas directas.
                </p>
              </>
            )}

            {(concept.indexOf('Remolque m') !== -1) && (
              <>
                <p>
                  El asegurador garantiza el reembolso de los gastos incurridos por el asegurado con ocasión del remolque de la embarcación asegurada hasta el puerto más próximo al lugar donde ésta se encuentre en el momento de recibir la asistencia en caso de avería, accidente de mar u otra circunstancia que ocurra durante la navegación y le impida continuar su singladura y llegar a puerto por medios propios.
                </p>
              </>
            )}

            {(concept.indexOf('Defensa j') !== -1) && (
              <>
                <p>
                  El asegurador asume los gastos derivados de la defensa jurídica de los intereses del asegurado en el ámbito del uso particular o de recreo de la embarcación descrita en las condiciones particulares, así como de las otras prestaciones cubiertas por esta cobertura que se enumeran a continuación:
                </p>

                <div className="ml-2">
                  - Defensa penal
                  <br />
                  - Fianzas
                  <br />
                  - Reclamación de daños
                  <br />
                  - Reclamación por reparación defectuosa
                  <br />
                  - Reclamación por incumplimiento de contrato de garantía
                  <br />
                  - Defensa por infracciones administrativas
                  <br />
                  - Defensa de derechos por otros seguros
                  <br />
                  - Asistencia jurídica telefónica
                </div>
              </>
            )}

            {(concept.indexOf('Remoci') !== -1) && (
              <>
                <p>
                  Mediante esta cobertura el asegurador indemnizará los gastos de la retirada de los restos de la embarcación asegurada dispuesta por la Autoridad Marítima competente, hasta el capital máximo de indemnización fijado en las condiciones particulares.
                </p>
              </>
            )}

            {(concept.indexOf('Asistencia') !== -1) && (
              <>
                <p>
                  A los efectos de las coberturas relativas a la embarcación se considerará como asegurado al propietario de la misma o al tomador del seguro designado en las condiciones particulares de la póliza, ampliándose la consideración de asegurados a los ocupantes de la embarcación que viajen en ella a título gratuito, cuando se trate de las coberturas relativas a las personas.
                </p>

                <div className="ml-2">
                  <div className="font-weight-bold mb-1">
                    Coberturas relativas a la embarcación:
                  </div>
                  <div className="ml-2">
                    - Transporte terrestre o asistencia técnico-mecánica de la embarcación
                    <br />
                    - Búsqueda y envío de piezas de recambio
                  </div>

                  <br />

                  <div className="font-weight-bold mb-1">
                    Coberturas relativas a las personas:
                  </div>
                  <div className="ml-2">
                    <div className="font-italic mb-1">
                      Derivadas de la inmovilización de la embarcación:
                    </div>
                    <div className="ml-2">
                      - Gastos de alojamiento y transporte de asegurados
                      <br />
                      - Gastos de transporte del asegurado para recoger su embarcación
                      <br />
                      - Gastos de custodia de la embarcación
                    </div>

                    <div className="font-italic mt-2 mb-1">
                      Durante un viaje o desplazamiento (singladura)
                    </div>
                    <div className="ml-2">
                      - Transporte o repatriación sanitaria de heridos y enfermos
                      <br />
                      - Repatriación o transporte de los asegurados
                      <br />
                      - Desplazamiento de un familiar del asegurado en caso de hospitalización
                      <br />
                      - Gastos de prolongación de estancia en un hotel
                      <br />
                      - Traslado o repatriación del asegurado fallecido
                      <br />
                      - Regreso anticipado
                      <br />
                      - Robo de equipajes o efectos personales
                      <br />
                      - Adelanto de fondos en el extranjero
                      <br />
                      - Gastos médicos en el extranjero
                    </div>
                  </div>
                </div>
              </>
            )}

            {(concept.indexOf('Accidentes') !== -1) && (
              <>
                <p>
                  El Seguro de embarcaciones de recreo cubre a todos los ocupantes, con indemnizaciones en caso de fallecimiento o invalidez permanente. <br />
                  También se garantizan los gastos de asistencia médica si se produce un accidente a bordo al subir o bajar de la embarcación.
                </p>

                <div className="ml-2">
                  <dl>
                    <dt>Muerte</dt>
                    <dd className="ml-2">Suma asegurada: 6.000€</dd>
                    <dt>Invalidez permanente</dt>
                    <dd className="ml-2">Suma asegurada: 12.000€</dd>
                    <dt>Asistencia sanitaria</dt>
                    <dd className="ml-2">Suma asegurada: 1.200€</dd>
                  </dl>
                </div>
              </>
            )}

            <p className="font-italic small mt-5">
              *El texto anterior es solo un resumen únicamente con carácter informativo.<br />
              Contacta con nosotros para obtener los detalles de cada cobertura.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleInfoModalClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <div
          onClick={this.handleOnClick}
          className={`a ${small && 'small d-inline-block mr-2'}`}
        >
          <div className="border rounded mb-2 d-block">
            <div
              className="float-right"
              style={{
                height: 0,
                width: 0,
                position: 'relative',
                right: '20px',
              }}
            >
              <FontAwesomeIcon icon={faInfoCircle} style={{ opacity: 0.75 }} />
            </div>
            <div className={`p-2 ${small && 'pr-4'}`}>
              {concept}
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Concept
