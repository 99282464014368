import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import MainLinks from './../MainLinks'

export default class Footer extends Component {
  render () {
    return (
      <>
        <footer className="text-muted pb-5 pt-4 mt-5">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6">

                <div className="row">
                  <div className="col">
                    <img src="/resources/img/logo.svg" height="50px" />
                  </div>
                </div>

              </div>
              <div className="col-12 col-md-6">
                <br className="d-lg-none" />
                <br className="d-lg-none" />
                <div className="d-md-flex justify-content-center justify-content-md-end">
                  <MainLinks />
                </div>
              </div>
            </div>
            <hr />
            <div className="d-flex justify-content-between align-content-center pb-4">
              <div>
                <Link to="/nota-legal">
                  Nota legal
                </Link>
                {' '}-{' '}
                <Link to="/politica-cookies">
                  Política de cookies
                </Link>
              </div>
              <div>
                <a
                  href="http://llort-tarradas.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="/resources/img/llort-tarradas.svg" height="30px" />
                </a>
              </div>
            </div>
          </div>
        </footer>
      </>
    )
  }
}
