import React from 'react'
import { observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'

import Store from './../../../mobx'
import Component from './index'
import withNavbar from '../../ui/withNavbar'

const Wrap = withRouter(withNavbar(Component))

const { offers, policy } = Store

export default observer(props => (
  <Wrap
    {...props}
    offers={offers.offers}
    policy={policy.policy}
    coberturaDanos={policy.policy.coberturaDanos}

    setSelectedOffer={(selectedOffer) => {
      offers.setSelectedOffer(selectedOffer)
    }}
  />
))
