import React from 'react'
import { observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'

import Store from './../../../../mobx'
import Component from './index'

const { policy, offers } = Store

const Wrap = withRouter(Component)

export default observer(props => (
  <Wrap
    {...props}

    setPolicy={(policyData) => {
      policy.setPolicy(policyData)
    }}

    setOffers={(offersData) => {
      offers.setOffers(offersData)
    }}
  />
))
