import React from 'react'
import { Modal, Button } from 'react-bootstrap'


function TeLLamamosModal ({ shown, onClickCancel }) {
  return (
    <Modal show={shown}>
      <Modal.Body className="text-center">
        <h3>
          No somos robots <br />
          <img src="/resources/img/no-robots.svg" width="125px" /><br />
          <small>Queremos darte un trato personal</small>
        </h3>

        <hr />

        <div className="text-left">
          <p>
            <span className="text-italic">Primero te enviamos nuestra propuesta</span>
            <br />
            y después, con una llamada te vamos a:
          </p>
          <div className="mt-3 ml-3 mr-3">
            <dl>
              <dt>- Asesorar para asegurar tus necesidades</dt>
              <dt>- Explicar nuestra propuesta paso a paso</dt>
              <dt>- Resolver cualquier duda</dt>
            </dl>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant="secondary"
          size="lg"
          onClick={onClickCancel}
        >
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default TeLLamamosModal
