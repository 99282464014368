import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCookieBite } from '@fortawesome/free-solid-svg-icons'

class Cookies extends Component {
  state = {
    shown: !window.localStorage.getItem('cookiesOk'),
  }

  onClose = () => {
    this.setState({
      shown: false,
    })

    localStorage.setItem('cookiesOk', 1)
  }

  render () {
    const { shown } = this.state

    return (
      <>
        {shown &&
          <div
            aria-live="polite"
            aria-atomic="true"
            style={{
              position: 'fixed',
              bottom: '20px',
              left: '20px',
              zIndex: 1000000000,
            }}
          >
            <div className={`toast ${shown && 'show'}`} role="alert" aria-live="assertive" aria-atomic="true">
              <div className="toast-header">
                <span style={{ opacity: 0.75 }}>
                  <FontAwesomeIcon icon={faCookieBite} />
                </span>
                <strong className="mr-auto ml-1"> Cookies</strong>
                <button
                  onClick={this.onClose}
                  type="button"
                  className="ml-2 mb-1 close"
                  data-dismiss="toast"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="toast-body">
                Utilizamos cookies. <a target="_blank" href="/politica-cookies">Más info</a>.
              </div>
            </div>
          </div>
        }
      </>
    )
  }
}

export default Cookies
