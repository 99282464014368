import { observable, action } from 'mobx'
import LocalStorage from './../../../services/local-storage'

class Policy {
  @observable
  policy = LocalStorage.get('policy') || {}

  @action
  setPolicy (policy) {
    this.policy = policy
    LocalStorage.set('policy', policy)
  }
}

export default new Policy()
