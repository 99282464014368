import React, { Component } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

import ScrollTo from './../../../services/scroll-to'
import SegurosApi from './../../../services/seguros-api'

import DanosInfoModal from './DanosInfoModal'
import EnBreveModal from './EnBreveModal'
import EmailModal from './EmailModal'
import Concept from './Concept'


class Ofertas extends Component {
  state = {
    showDanosInfoModal: true,
    enBreveModalShown: false,
    emailModalShown: false,
  }

  componentDidMount () {
    ScrollTo.top()
  }

  onClickContratar = (selectedOffer) => {
    const { setSelectedOffer, history } = this.props
    setSelectedOffer(selectedOffer)
    history.push('/contratar-seguro')
  }

  onClickRecibirEmail = () => {
    SegurosApi.recibirEmail(this.props.policy)

    this.setState({
      emailModalShown: true,
    })
  }

  onClickSolicitar = () => {
    SegurosApi.recibirLlamada({
      ...this.props.policy,
      solicitarPrecioAccidentes: true,
    })

    this.setState({
      enBreveModalShown: true,
    })
  }

  onClickSolicitarLlamada = () => {
    SegurosApi.recibirLlamada(this.props.policy)

    this.setState({
      enBreveModalShown: true,
    })
  }

  handleOnCloseDanosInfoModal = () => {
    this.setState({
      showDanosInfoModal: false,
    })
  }

  render () {
    const {
      showDanosInfoModal,
      enBreveModalShown,
      emailModalShown,
    } = this.state
    const { offers, coberturaDanos } = this.props

    return (
      <div>
        <DanosInfoModal
          onClickClose={this.handleOnCloseDanosInfoModal}
          shown={showDanosInfoModal && coberturaDanos}
        />

        <EnBreveModal
          shown={enBreveModalShown}
        />

        <EmailModal
          shown={emailModalShown}
        />

        <div className="container pt-5 pb-2">
          <div className="card-deck mb-3 text-center">

            {offers.map((offer, offerIndex) => <>
              {(offerIndex % 2 === 0) && (
                <div className="w-100 d-none d-sm-block d-lg-none" />
              )}

              <div className="card mb-4 shadow-sm border">
                <div className="card-header">
                  <h2 className="my-0 squada">
                    {offer.name}
                  </h2>
                </div>
                <div className="card-body">
                  <h1 className="card-title pricing-card-title">
                    {offer.price && offer.price}
                    {!offer.price && <span>??,??</span>}
                    <span> €</span>
                  </h1>
                  <hr className="hr-no-margin" />

                  {offer.includes.map(concept => <>
                    <Concept concept={concept} />
                  </>)}

                  {/* <ul className="list-group list-group-flush">
                    {offer.includes.map(concept => <>
                      <li className="list-group-item">
                        {concept}
                      </li>
                    </>)}
                  </ul> */}
                </div>
                <div className="card-footer">
                  {offer.price &&
                    <button
                      onClick={() => {
                        this.onClickContratar(offer)
                      }}
                      type="button"
                      className="btn btn-lg btn-block btn-primary"
                    >
                      CONTRATAR
                    </button>
                  }
                  {!offer.price &&
                    <button
                      onClick={() => {
                        this.onClickSolicitar()
                      }}
                      type="button"
                      className="btn btn-lg btn-block btn-primary"
                    >
                      SOLICITAR
                    </button>
                  }
                </div>
              </div>
            </>)}
          </div>

          <div className="card border">
            <div className="card-header">
              <h2 className="my-0 squada">
                O si lo prefieres...
              </h2>
            </div>

            <div className="card-body">
              <div className="d-none d-md-block">
                <button
                  type="button"
                  className="btn btn-lg btn-primary mr-4"
                  onClick={this.onClickSolicitarLlamada}
                >
                  SOLICITAR LLAMADA
                </button>
                <button
                  type="button"
                  className="btn btn-lg btn-primary"
                  onClick={this.onClickRecibirEmail}
                >
                  RECIBIR OFERTAS POR EMAIL
                </button>
              </div>

              <div className="d-block d-md-none">
                <button
                  type="button"
                  className="btn btn-lg btn-primary btn-block mr-4"
                  onClick={this.onClickSolicitarLlamada}
                >
                  SOLICITAR LLAMADA
                </button>
                <hr className="d-block d-md-none" />
                <button
                  type="button"
                  className="btn btn-lg btn-primary btn-block"
                  onClick={this.onClickRecibirEmail}
                >
                  RECIBIR OFERTAS POR EMAIL
                </button>
              </div>
            </div>
          </div>

        </div>
      </div>
    )
  }
}

export default Ofertas
