const LocalStorage = {
  set (element, value) {
    localStorage.setItem(element, JSON.stringify(value))
  },

  get (element) {
    const elementValue = localStorage.getItem(element)
    return elementValue !== 'undefined' ? JSON.parse(elementValue) : undefined
  },
}

export default LocalStorage
