import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'

class WhatsappContact extends Component {
  render () {
    return (
      <a
        className="d-sm-block d-md-none"
        aria-live="polite"
        aria-atomic="true"
        href="https://wa.me/+34630775550"
        target="_blank"
        rel="noopener noreferrer"
        style={{
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          zIndex: 1000000000,
          lineHeight: 0,
          fontSize: '35px',
        }}
      >
        <div className="toast show" role="alert" aria-live="assertive" aria-atomic="true">
          <div className="toast-header p-2">
            <span
              style={{
                opacity: 0.75,
                fontSize: '40px',
                position: 'relative',
                left: '3px',
              }}
            >
              <FontAwesomeIcon icon={faWhatsapp} />
            </span>
            <strong className="mr-auto ml-1"></strong>
          </div>
        </div>
      </a>
    )
  }
}

export default WhatsappContact
