import React from 'react'

function Tel516 (props) {
  return (
    <a
      href="tel:+34677977516"
      target="_blank"
      rel="noopener noreferrer"
    >
      +34 677 977 516
    </a>
  )
}

export default Tel516
