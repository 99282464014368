import { observable, action } from 'mobx'
import LocalStorage from './../../../services/local-storage'


class Offers {
  @observable
  offers = LocalStorage.get('offers') || {}

  @observable
  selectedOffer = LocalStorage.get('selectedOffer') || {}

  @action
  setOffers (offers) {
    this.offers = offers
    LocalStorage.set('offers', offers)
  }

  @action
  setSelectedOffer (selectedOffer) {
    this.selectedOffer = selectedOffer
    LocalStorage.set('selectedOffer', selectedOffer)
  }
}

export default new Offers()
