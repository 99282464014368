import React, { Component, Fragment } from 'react'

import Header from '../../Header'
import Footer from '../../Footer'


function withNavbarHOC (WrappedComponent) {
  return class WithNavbarHOC extends Component {
    render () {
      return <Fragment>
        <Header />
        <WrappedComponent {...this.props} />
        <Footer />
      </Fragment>
    }
  }
}

export default withNavbarHOC
