import React, { Component } from 'react'

class NotaLegal extends Component {
  render () {
    return (
      <div className="bg-light">
        <div className="container p-5">

          <div className="post-title">
            <h1 className="h1">Nota legal</h1>
          </div>

          <div className="post-content">
            <h4>Política de privacidad</h4>

            <p>LLORT TARRADAS SL se compromete a proteger la privacidad de los usuarios que accedan a esta web y/o cualquiera de sus
              servicios. La utilización de la web y/o de cualquiera de los servicios ofrecidos por Llort Tarradas SL implica la
              aceptación por el usuario de las disposiciones contenidas en la presente Política de Privacidad y que sus datos
              personales sean tratados según se estipula en ella. Por favor, tenga en cuenta que a pesar de que pueda haber enlaces
              de nuestra web a otras webs o redes sociales, esta Política de Privacidad no se aplica a las webs de otras compañías u
              organizaciones a las que la web esté redirigida. Llort Tarradas SL no controla el contenido de las webs de terceros, ni
              acepta cualquier responsabilidad por el contenido o las políticas de privacidad de estas webs.</p>


            <h5>Información básica sobre el tratamiento de datos Reglamento (UE) 2016/679</h5>

            <table className="table mt-2">
              <tbody>
                <tr>
                  <td><b>Responsable del tratamiento</b></td>
                  <td>Llort Tarradas SL<br />
                    NIF: B-65937468<br />
                    Carrer Romero de Torres, 32, 08397 Pineda de mar (BARCELONA)<br />
                    Email: info@Llort Tarradas.com</td>
                </tr>
                <tr>
                  <td><b>Finalidad del tratamiento</b></td>
                  <td>Ofrecer y gestionar nuestros servicios de asesoramiento de seguros.</td>
                </tr>
                <tr>
                  <td><b>Legitimación</b></td>
                  <td>
                    <ul>
                      <li>Consentimiento obtenido del interesado.</li>
                      <li>Ejecución del contrato de servicios</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td><b>Destinatarios</b></td>
                  <td>Los datos no serán comunicados a terceros, salvo que lo exija una Ley o sea necesario para cumplir con la
                    finalidad del tratamiento.</td>
                </tr>
                <tr>
                  <td><b>Derechos de las personas</b></td>
                  <td>Los interesados tienen derecho a ejercer los derechos de acceso, rectificación, limitación de tratamiento,
                    supresión, portabilidad y oposición, enviando su solicitud a nuestra dirección.</td>
                </tr>
                <tr>
                  <td><b>Plazo de conservación de los datos</b></td>
                  <td>Mientras se mantenga la relación comercial o durante los años necesarios para cumplir con las obligaciones
                    legales.</td>
                </tr>
                <tr>
                  <td><b>Reclamación</b></td>
                  <td>Los interesados pueden dirigirse a la AEPD para presentar la reclamación que considere oportuna.</td>
                </tr>
                <tr>
                  <td><b>Información adicional</b></td>
                  <td>Puede consultar la información adicional y detallada a continuación en las «Preguntas sobre privacidad»</td>
                </tr>
              </tbody>
            </table>

            <h4>Preguntas sobre privacidad</h4>

            <p>En cumplimiento del Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, (RGPD), le
              ofrecemos la siguiente información sobre el tratamiento de sus datos personales::</p>


            <h5>¿Quién es el responsable del tratamiento de sus datos?</h5>

            <p>Identidad: Llort Tarradas SL</p>
            <p>NIF: B-65937468</p>
            <p>Dirección: Carrer Romero de Torres, 32, 08397 Pineda de mar (BARCELONA)</p>
            <p>Tel.: 93 767 22 07</p>
            <p>Email: info@Llort Tarradas.com</p>


            <h5>¿Con qué finalidad tratamos sus datos personales?</h5>

            <ul>
              <li>Tratamos la información que se nos facilita para gestionar nuestros servicios de asesoramiento de seguros.</li>
              <li>En el caso de que se ponga en contacto con nosotros a través del formulario de contacto de nuestra página web, los
                trataremos para gestionar su consulta.</li>
              <li>Si nos da su consentimiento también podremos tratar sus datos para enviarle información sobre nuestras
                actividades, productos o servicios.</li>
              <li>Si nos envía un currículum, trataremos los datos con el fin de gestionar la base de CV ‘s para la selección de
                personal.</li>
            </ul>


            <h5>¿Cuánto tiempo conservaremos sus datos?</h5>

            <ul>
              <li>Los datos personales proporcionados se conservarán mientras sea usuario de nuestros servicios o quiera recibir
                información, dado que puede oponerse al tratamiento de sus datos con fines promocionales en cualquier momento, y
                luego, durante los plazos establecidos para cumplir con nuestras obligaciones legales, que en el caso documentación
                contable y fiscal a efectos mercantiles serán 6 años, de conformidad con el Art. 30 del Código Comercio, y a efectos
                fiscales serán 4 años, de acuerdo con los artículos 66 a 70 de la Ley General Tributaria.</li>
              <li>En el caso de los currículums los datos se conservarán durante un año.</li>
            </ul>


            <h5>¿Cuál es la legitimación para el tratamiento de sus datos?</h5>

            <p>La legitimación para tratarlos se encuentra en la ejecución del contrato de servicios y en los consentimientos que
              nos da.<br />
              Respecto a aquella información que sea remitida por menores de 16 años, será requisito imprescindible que se haga con
              el consentimiento paterno, del tutor o del legal representante del menor para que los datos personales puedan ser
              objeto de tratamiento. Si no es así, el representante legal del menor nos lo comunicará tan pronto como tenga
              conocimiento.</p>


            <h5>¿A qué destinatarios se comunicarán sus datos?</h5>

            <p>Los datos no serán comunicados a terceros, salvo que lo exija una Ley o sea necesario para cumplir con la finalidad
              del tratamiento.</p>


            <h5>¿Cuáles son sus derechos cuando nos facilita sus datos?</h5>

            <ul>
              <li>Cualquier persona tiene derecho a obtener confirmación sobre si estamos tratando o no sus datos personales.</li>
              <li>Los interesados tienen derecho a acceder a sus datos personales, así como a solicitar la rectificación de los
                datos inexactos o, en su caso, solicitar su supresión cuando, entre otros motivos, los datos ya no sean necesarios
                para las finalidades para las que se recogieron.</li>
              <li>En determinadas circunstancias los interesados podrán solicitar la limitación del tratamiento de sus datos, en
                este caso únicamente los conservaremos para el ejercicio o la defensa de reclamaciones.</li>
              <li>También, en determinadas circunstancias y por motivos relacionados con su situación particular, los interesados
                pueden oponerse al tratamiento de sus datos. En este caso dejaremos de tratar los datos, excepto por motivos
                legítimos imperiosos o para el ejercicio o la defensa de posibles reclamaciones.</li>
              <li>Los interesados también tienen derecho a la portabilidad de sus datos.</li>
              <li>Todo interesado tendrá derecho a no ser objeto de una decisión basada únicamente en el tratamiento automatizado,
                incluida la elaboración de perfiles, que produzca efectos jurídicos en él o le afecte significativamente de modo
                similar.</li>
              <li>Finalmente, los interesados tienen derecho a presentar una reclamación ante la Autoridad de Control competente.
              </li>
            </ul>


            <h5>¿Cómo puede ejercer sus derechos?</h5>

            <p>Enviando un escrito adjuntando una copia de un documento que lo identifique, a nuestra dirección física o la
              electrónica.</p>


            <h5>¿Cómo hemos obtenido sus datos?</h5>

            <p>Los datos personales que tratamos proceden del propio interesado. El interesado garantiza que los datos personales
              facilitados son ciertos y se hace responsable de comunicar cualquier modificación de estos. Los datos que estén
              marcados con un asterisco serán obligatorios para poder darle el servicio solicitado.</p>


            <h5>¿Qué datos tratamos?</h5>

            <p>Las categorías de datos que podemos tratar en la prestación de nuestros servicios son:</p>
            <ul>
              <li>Datos de carácter identificativo</li>
              <li>Direcciones postales o electrónicas</li>
            </ul>
            <p>En el caso de los currículos, también:</p>
            <ul>
              <li>Características personales</li>
              <li>Académicos y profesionales</li>
            </ul>
            <p>Los datos son limitados, dado que únicamente tratamos los datos necesarios para la prestación de nuestros servicios y
              la gestión de nuestra actividad.</p>


            <h5>¿Usamos cookies?</h5>

            <p>Utilizamos cookies durante la navegación en nuestra web con el consentimiento del usuario.</p>
            <p>El usuario puede configurar su navegador para ser avisado de la utilización de cookies y para evitar su uso. Por
              favor, visite nuestra política de cookies.</p>


            <h5>¿Qué medidas de seguridad aplicamos?</h5>

            <p>Aplicamos las medidas de seguridad establecidas en el artículo 32 del RGPD, por tanto, hemos adoptado las medidas de
              seguridad necesarias para garantizar un nivel de seguridad adecuado al riesgo del tratamiento de datos que realizamos,
              con mecanismos que nos permiten garantizar la confidencialidad, integridad, disponibilidad y resiliencia permanente de
              los sistemas y servicios de tratamiento.</p>
            <p>Algunas de estas medidas son:</p>
            <ul>
              <li>Información de las políticas de tratamiento de datos al personal.</li>
              <li>Realización de copias de seguridad periódicas</li>
              <li>Control de acceso a los datos</li>
              <li>Procesos de verificación, evaluación y valoración regulares.</li>
            </ul>


            <h3>Aviso legal</h3>


            <h4>Información de conformidad con el artículo 10 de la LSSI</h4>


            <h5>La titular de esta web es:</h5>

            <p>Identidad: Llort Tarradas SL<br />
              NIF: B-65937468<br />
              Dirección: 08397 Pineda de mar (BARCELONA)<br />
              Tel.: 93 767 22 07<br />
              Email: info@Llort Tarradas.com<br />
              {/* Inscrita en el Registro Mercantil de Girona, inscripción ____, Hoja ______, Folio ____, Tomo ___ */}
            </p>

            <h5>Condiciones de uso</h5>
            <p>El uso de esta web implica la aceptación plena de los términos y condiciones del presente aviso legal. Los posibles
              conflictos relativos a esta web se regirán exclusivamente por el derecho del Estado español. Toda persona usuaria de
              la web, independientemente de la jurisdicción territorial desde la que se produzca su acceso, acepta el cumplimiento y
              respeto de estas cláusulas con renuncia expresa a cualquier otro fuero que pudiera corresponderle.</p>

            <h5>Propiedad intelectual e industrial</h5>

            <p>La totalidad del contenido de este sitio web, ya sean textos, imágenes, sonidos, marcas, logotipos u otros elementos,
              así como subestructura, diseño, combinaciones de colores o forma de presentación de los materiales, están protegidos
              por derechos de propiedad industrial e intelectual que el usuario de este sitio web debe respetar. No es lícita su
              transformación o alteración, comunicación pública o cualquier otra forma de explotación por cualquier procedimiento
              sin la autorización expresa de Llort Tarradas SL.</p>

            <h5>Responsabilidad sobre los contenidos</h5>

            <p>Aunque Llort Tarradas SL actúa con la máxima diligencia posible, puede darse el caso de que algún dato o información no
              esté del todo actualizada en el momento que el usuario del sitio web lo consulte. Por ello, las informaciones que se
              presentan en esta web tienen una función orientativa y no obligan a Llort Tarradas SL.</p>
            <p>Llort Tarradas SL no será responsable de la información que se pueda obtener a través de enlaces incluidos en su web.
            </p>
            <p>Llort Tarradas SL se reserva la facultad de realizar, en cualquier momento y sin necesidad de preaviso, modificaciones
              o actualizaciones de la información y de cualquiera de los elementos que integren el diseño y configuración de la
              página web.</p>

            <h5>Ley aplicable y jurisdicción:</h5>

            <p>El uso de este sitio web implica la aceptación plena de los términos y condiciones de la presente política legal. Los
              posibles conflictos relativos a esta web se regirán exclusivamente por la legislación española, siendo competentes los
              Juzgados y Tribunales españoles para conocer de cuantas cuestiones se susciten sobre ella. El usuario, por virtud de
              su aceptación renuncia expresamente a cualquier fuero que por aplicación de la Ley de Enjuiciamiento Civil vigente
              pudiera corresponderle.</p>

          </div>

        </div>
      </div>
    )
  }
}

export default NotaLegal
