import React, { PureComponent } from 'react'
import Form from './Form/container'
import TeLLamamosModal from './TeLLamamosModal'

const defaultState = {
  showTeLlamamosModal: false,
}

class CalcularPrecio extends PureComponent {
  state = {
    ...defaultState,
  }

  handleOnClickPorque = () => {
    this.setState({
      showTeLlamamosModal: true,
    })
  }

  handleOnClickModalCancel = () => {
    this.setState({
      showTeLlamamosModal: false,
    })
  }

  render () {
    const {
      showTeLlamamosModal,
    } = this.state

    return (
      <div>
        <div className="container pt-5 pb-5">

          <div className="row">
            <div className="col-md"></div>
            <div className="col-md-10">
              <div className="card card-calcular-precio">
                <div className="card-body">
                  <TeLLamamosModal
                    shown={showTeLlamamosModal}
                    onClickCancel={this.handleOnClickModalCancel}
                  />

                  <Form
                    type={new URLSearchParams(window.location.search).get('tipo')}
                    onClickPorque={this.handleOnClickPorque}
                  />
                </div>
              </div>
            </div>
            <div className="col-md"></div>
          </div>

        </div>
      </div>
    )
  }
}

export default CalcularPrecio
