import React from 'react'
import { observer } from 'mobx-react'

import Store from './../../../../mobx'
import Component from './index'


const { offers } = Store

export default observer(props => (
  <Component
    {...props}
    selectedOffer={offers.selectedOffer}
  />
))
