import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'

class DanosInfoModal extends Component {
  render () {
    const { shown, onClickClose } = this.props

    return (
      <Modal show={shown}>
        <Modal.Body className="text-center">
          <div className="text-left">
            <h3>
              Las siguientes ofertas no tienen incluidas la cobertura de daños
              <hr />
              <small>
                Te enviaremos un email completo con todas las ofertas <span className="text-muted">(incluyendo la cobertura de daños)</span>.
              </small>
            </h3>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            size="lg"
            onClick={onClickClose}
          >
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default DanosInfoModal
