import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'

class MainLinks extends Component {
  render () {
    return (
      <>
        <NavLink className="nav-link main-link" to="/" exact>Inicio</NavLink>
        <NavLink className="nav-link main-link" to="/calcular-precio">Calcular precio</NavLink>
        <NavLink className="nav-link main-link" to="/informacion-poliza">Información póliza</NavLink>
        <NavLink className="nav-link main-link" to="/quienes-somos">Nosotros</NavLink>
        <NavLink className="nav-link main-link" to="/contacto">Contacto</NavLink>
      </>
    )
  }
}

export default MainLinks
