const SegurosApi = {
  createXhr (phpFile, data) {
    const xhr = new XMLHttpRequest()
    xhr.open('POST', `${window.location.origin}/resources/php/${phpFile}`, true)
    xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8')

    return new Promise((resolve, reject) => {
      // request state change event
      xhr.onreadystatechange = function () {
        // request completed?
        if (xhr.readyState !== 4) return

        if (xhr.status === 200) {
          resolve()
        } else {
          reject(new Error('no 200'))
        }
      }

      // start request
      xhr.send(JSON.stringify(data))
    })
  },

  calcularPrecio (data) {
    return this.createXhr('calcular-precio-form', data)
  },

  ofertasMostradas (data) {
    return this.createXhr('ofertas-mostradas', data)
  },

  contratarSeguro (data) {
    return this.createXhr('contratar-seguro', data)
  },

  recibirLlamada (data) {
    return this.createXhr('recibir-llamada', data)
  },

  recibirEmail (data) {
    return this.createXhr('recibir-email', data)
  },
}

window.SegurosApi = SegurosApi

export default SegurosApi
