import React, { Component } from 'react'
import { Accordion, Button } from 'react-bootstrap'

class FAQ extends Component {
  onToggleClick = () => {
    try {
      window.ga('send', {
        hitType: 'event',
        eventCategory: 'Accordion',
        eventAction: 'toggle',
      })
    } catch (error) {
      console.error(error)
    }
  }

  render () {
    return (
      <Accordion defaultActiveKey="0">
        <div className="card">
          <div className="card-header" id="headingOne">
            <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={this.onToggleClick}>
              <h2 className="mb-0">
                <button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne"
                  aria-expanded="true" aria-controls="collapseOne">
                  Responsabilidad civil obligatoria y voluntaria
                </button>
              </h2>
            </Accordion.Toggle>
          </div>

          <Accordion.Collapse eventKey="0">
            <div className="card-body">
              <p>El<strong> Seguro de embarcaciones</strong> contempla la responsabilidad civil obligatoria en las
                condiciones y límites que recoge en el Reglamento del Seguro de Responsabilidad Civil Obligatoria para
                Embarcaciones de Recreo o Deportivas (RD 607/1999).</p>
              <p>Además, puedes ampliar esta cobertura con la responsabilidad civil voluntaria, pudiendo llegar hasta
                los 2 millones de euros y ampliando el ámbito de cobertura durante la estancia de la embarcación en
                tierra y en su transporte por carretera.</p>
              <p>Asimismo, puedes incorporar ampliaciones como la responsabilidad civil de pesca deportiva o la
                responsabilidad civil de la embarcación mientras permanece en varadero, entre otras.</p>
            </div>
          </Accordion.Collapse>
        </div>

        <div className="card">
          <div className="card-header" id="headingTwo">
            <Accordion.Toggle as={Button} variant="link" eventKey="1" onClick={this.onToggleClick}>
              <h2 className="mb-0">
                <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo"
                  aria-expanded="false" aria-controls="collapseTwo">
                  Daños propios
                </button>
              </h2>
            </Accordion.Toggle>
          </div>
          <Accordion.Collapse eventKey="1">
            <div className="card-body">
              <div className="content">
                <p>¿Has sufrido alguna pérdida o daño directo mientras navegabas? Con el <strong>Seguro de
                    embarcaciones de recreo </strong>lo tendrás cubierto, a través de la siguiente gama de coberturas:
                </p>

                <ul>
                  <li>Pérdida total efectivo o constructiva</li>
                  <li>Abandono</li>
                  <li>Daños parciales</li>
                  <li>Robo y daños por tentativa</li>
                  <li>Efectos personales</li>
                </ul>

                <p>Además, no sólo te garantizamos los diferentes riesgos durante la navegación, sino que también
                  puedes ampliar tu cobertura en estas situaciones:</p>

                <ul>
                  <li>Embarcación en tierra</li>
                  <li>Transporte por carretera, carga y descarga</li>
                  <li>Maniobras de entrada y salida del agua</li>
                  <li>Actos vandálicos o malintencionados</li>
                  <li>Fenómenos meteorológicos de carácter extraordinario</li>
                </ul>

              </div>
            </div>
          </Accordion.Collapse>
        </div>

        <div className="card">
          <div className="card-header">
            <Accordion.Toggle as={Button} variant="link" eventKey="2" onClick={this.onToggleClick}>
              <h2 className="mb-0">
                <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree"
                  aria-expanded="false" aria-controls="collapseThree">
                  Gastos de remolque y remoción
                </button>
              </h2>
            </Accordion.Toggle>
          </div>
          <Accordion.Collapse eventKey="2">
            <div className="card-body">
              <p>El<strong> Seguro de embarcaciones</strong>&nbsp;cubre también los gastos para salvar la embarcación
                de su destrucción o desaparición, el remolque de asistencia marítima y la remoción de restos impuesta
                por la Autoridad.</p>
            </div>
          </Accordion.Collapse>
        </div>

        <div className="card">
          <div className="card-header">
            <Accordion.Toggle as={Button} variant="link" eventKey="3" onClick={this.onToggleClick}>
              <h2 className="mb-0">
                <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree"
                  aria-expanded="false" aria-controls="collapseThree">
                  Daños a equipamiento personal
                </button>
              </h2>
            </Accordion.Toggle>
          </div>
          <Accordion.Collapse eventKey="3">
            <div className="card-body">
              <p>Tendrás todo cubierto si se produce un daño en algún equipo u objeto personal de sus ocupantes debido
                a
                una avería, robo o pérdida total de la embarcación.&nbsp;</p>
            </div>
          </Accordion.Collapse>
        </div>

        <div className="card">
          <div className="card-header">
            <Accordion.Toggle as={Button} variant="link" eventKey="4" onClick={this.onToggleClick}>
              <h2 className="mb-0">
                <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree"
                  aria-expanded="false" aria-controls="collapseThree">
                  Defensa jurídica
                </button>
              </h2>
            </Accordion.Toggle>
          </div>
          <Accordion.Collapse eventKey="4">
            <div className="card-body">
              <p>Dispondrás de un equipo jurídico para cualquier tema de defensa penal, fianzas y reclamación a
                terceros.
                También puedes contar con asistencia jurídica telefónica.&nbsp;</p>
            </div>
          </Accordion.Collapse>
        </div>

        <div className="card">
          <div className="card-header">
            <Accordion.Toggle as={Button} variant="link" eventKey="5" onClick={this.onToggleClick}>
              <h2 className="mb-0">
                <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree"
                  aria-expanded="false" aria-controls="collapseThree">
                  Accidentes personales
                </button>
              </h2>
            </Accordion.Toggle>
          </div>
          <Accordion.Collapse eventKey="5">
            <div className="card-body">
              <p>El <strong>Seguro de embarcaciones de recreo </strong>cubre a todos los ocupantes, con
                indemnizaciones en caso de fallecimiento o invalidez permanente. También se garantizan los gastos de
                asistencia médica si se produce un accidente a bordo o al subir o bajar de la embarcación.&nbsp;</p>
            </div>
          </Accordion.Collapse>
        </div>

        <div className="card">
          <div className="card-header">
            <Accordion.Toggle as={Button} variant="link" eventKey="6" onClick={this.onToggleClick}>
              <h2 className="mb-0">
                <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree"
                  aria-expanded="false" aria-controls="collapseThree">
                  Asistencia en singladura
                </button>
              </h2>
            </Accordion.Toggle>
          </div>
          <Accordion.Collapse eventKey="6">
            <div className="card-body">
              <p>Además de cubrir los gastos derivados del salvamento o remolque de asistencia martítima, también
                otorgamos cobertura a los gastos derivados de:</p>

              <ul>
                <li>Transporte terrestre o asistencia técnico-mecánica.</li>
                <li>Búsqueda y envío de piezas de repuesto.</li>
                <li>Alojamiento en espera, retorno de ocupantes y traslado para recoger la embarcación reparada.</li>
                <li>Repatriación sanitaria de heridos y enfermos, retorno anticipado por fallecimiento de familiar.
                </li>
                <li>Asistencia en caso de robo o pérdida de equipajes, adelanto de fondos.</li>
              </ul>
            </div>
          </Accordion.Collapse>
        </div>

      </Accordion>
    )
  }
}

export default FAQ
