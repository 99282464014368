import React from 'react'

function Tel788 ({ children }) {
  return (
    <a
      href="tel:+34629348788"
      target="_blank"
      rel="noopener noreferrer"
    >
      {children && children}
      {!children && <>+34 629 348 788</>}
    </a>
  )
}

export default Tel788
