import { configure } from 'mobx'

import Policy from './stores/policy'
import Offers from './stores/offers'

configure({ enforceActions: 'observed' })

class RootStore {
  policy = Policy
  offers = Offers
}

window.rootStore = new RootStore()

export default window.rootStore
