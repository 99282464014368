import React, { Fragment } from 'react'
import ReactDOM from 'react-dom'
import {
  BrowserRouter,
  Route,
  Switch,
  // Redirect,
} from 'react-router-dom'

import './styles/index.scss'

import Home from './components/pages/home/container'
import Seguro from './components/pages/seguro/container'
import CalcularPrecio from './components/pages/calcular-precio/container'
import ContratarSeguro from './components/pages/contratar-seguro/container'
import Ofertas from './components/pages/ofertas/container'
import QuienesSomos from './components/pages/quienes-somos/container'
import Contacto from './components/pages/contacto/container'

import PoliticaCookies from './components/pages/politica-cookies/container'
import NotaLegal from './components/pages/nota-legal/container'
import NotFound from './components/pages/not-found/container'


ReactDOM.render(
  <Fragment>
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/informacion-poliza" component={Seguro} />
        <Route exact path="/calcular-precio" component={CalcularPrecio} />
        <Route exact path="/ofertas" component={Ofertas} />
        <Route exact path="/contratar-seguro" component={ContratarSeguro} />
        <Route exact path="/quienes-somos" component={QuienesSomos} />
        <Route exact path="/contacto" component={Contacto} />
        <Route exact path="/politica-cookies" component={PoliticaCookies} />
        <Route exact path="/nota-legal" component={NotaLegal} />
        <Route component={NotFound} />

        {/* <Redirect exact from="/" to="/" /> */}
      </Switch>
    </BrowserRouter>
  </Fragment>,
  document.getElementById('app'),
)
