const ScrollTo = {
  top () {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  },
}

window.ScrollTo = ScrollTo

export default ScrollTo
