import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'

import Tel788 from './../Telefonos/788'
import Tel516 from './../Telefonos/516'


const ContactoLeft = () => {
  return (
    <div className="h6">
      <h4>
        <a
          href="//llort-tarradas.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/resources/img/llort-tarradas.svg" />
        </a>
      </h4>

      <hr className="m-4" />

      <div className="mb-2">
        <FontAwesomeIcon icon={faPhone} className="h3 mb-2 opacity-08" />
      </div>

      <div className="mb-2">
        <Tel788 />
      </div>

      <div className="mb-2">
        <Tel516 />
      </div>

      <hr className="m-4" />

      <div className="mb-2">
        <FontAwesomeIcon icon={faEnvelope} className="h3 mb-2 opacity-08" />
      </div>

      <div className="mb-2">
        <a
          href="tel:+34 629 348 788"
          target="_blank"
          rel="noopener noreferrer"
        >
          info@segurosnauticos.es
        </a>
      </div>
    </div>
  )
}

export default ContactoLeft
