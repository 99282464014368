import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import Tel788 from './../../Telefonos/788'


export default class Seguro extends Component {
  render () {
    return (

      <div>
        <div className="container pt-5">

          <div className="row">
            <div className="col-md">

              <div className="card mb-4">
                <div className="card-body">
                  <h4 className="font-weight-bold">
                    ¿Qué es?
                  </h4>
                  <hr />
                  <p>
                    <strong>
                      Este seguro tiene como objetivo compensar los perjuicios económicos por
                      la pérdida o los daños sufridos por tu
                      embarcación o por sus ocupantes, así
                      como, los causados a terceros.
                    </strong>
                  </p>

                  <p>
                    Podrás disfrutar del mar sin temor a echar
                    por la borda unas fantásticas vacaciones o
                    un espléndido fin de semana
                  </p>
                </div>
              </div>

              <div className="card mb-4">
                <div className="card-body">
                  <h4 className="font-weight-bold">
                    ¿Qué ofrece?
                  </h4>
                  <hr />
                  <p>
                    Al contratar el <strong>Seguro de embarcaciones
                    de recreo</strong>, obtienes la cobertura no solo para
                    los riesgos imprevisibles de la navegación,
                    sino también para los que pueden acontecer
                    en puerto, durante tu estancia o su transporte fuera del agua.
                  </p>

                  <p>
                    A partir de la base del seguro de responsabilidad civil obligatoria, que todos los
                    propietarios de embarcaciones de propulsión
                    a motor y, en general, todas las de eslora
                    superior a 6 metros deben suscribir, podrás
                    adaptar las coberturas del seguro para que
                    se ajuste a tus necesidades.
                  </p>
                </div>
              </div>

              <div className="card mb-4">
                <div className="card-body">
                  <h4 className="font-weight-bold">
                    ¿Cómo puedo contratarlo?
                  </h4>
                  <hr />
                  <p>
                    Rellene el formulario para calcular su precio (<Link to="/calcular-precio">click aquí</Link>),
                    contacte con nosotros directamente por teléfono (<Tel788 />)
                    o a través de la página de contacto (<Link to="/contacto">click aquí</Link>).
                  </p>
                </div>
              </div>

            </div>
            <div className="col-md">

              <div className="card mb-4">
                <div className="card-body">
                  <h4 className="font-weight-bold">
                    Un seguro con amplias coberturas:
                  </h4>
                  <hr />
                  <p>
                    <strong>
                      El Seguro de embarcaciones de recreo te permitirá cubrir todas tus necesidades a través de
                      una completa gama de coberturas, que podrás
                      combinar como prefieras:
                    </strong>
                  </p>

                  <div className="p-2">
                    <h5 className="font-weight-bold">
                      - Responsabilidad civil
                    </h5>
                    <ul>
                      <li>
                        Obligatoria según el Real Decreto 607/1999 (336.566,78€)
                      </li>
                      <li>
                        Voluntaria (ampliación de hasta 2.000.000€)
                      </li>
                      <li>
                        Pesca deportiva
                      </li>
                      <li>
                        Actividad en varadero
                      </li>
                    </ul>

                    <h5 className="font-weight-bold mt-4">
                      - Daños a la propia embarcación (casco, motores
                      y accesorios de navegación) y a la auxiliar
                    </h5>
                    <ul>
                      <li>
                        Pérdida total efectiva o constructiva
                      </li>
                      <li>
                        Abandono
                      </li>
                      <li>
                        Daños parciales
                      </li>
                      <li>
                        Robo y daños por tentativa
                      </li>
                      <li>
                        Efectos personales
                      </li>
                    </ul>

                    <h5 className="font-weight-bold mt-4">
                      - Gastos de auxilio y salvamento
                    </h5>

                    <h5 className="font-weight-bold mt-4">
                      - Gastos de remoción de restos
                    </h5>

                    <h5 className="font-weight-bold mt-4">
                      - Gastos de remolque de asistencia marítima
                    </h5>

                    <h5 className="font-weight-bold mt-4">
                      - Protección jurídica, civil y penal
                    </h5>

                    <h5 className="font-weight-bold mt-4">
                      - Accidentes que afecten a los ocupantes
                    </h5>
                  </div>

                </div>
              </div>

              <div className="card mb-4">
                <div className="card-body">
                  <h4 className="font-weight-bold">Servicios exclusivos:</h4>
                  <hr />

                  <div className="p-2">
                    <h5 className="font-weight-bold">
                      - Asistencia en viaje para los ocupantes
                    </h5>

                    <h5 className="font-weight-bold mt-4">
                      - Defensa y reclamación a terceros
                    </h5>

                    <h5 className="font-weight-bold mt-4">
                      - Asistencia jurídica telefónica
                    </h5>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>

    )
  }
}
