import React, { Component } from 'react'

import Form from './Form'
import ContactoLeft from './../../ContactoLeft'


export default class Contacto extends Component {
  render () {
    return (
      <div className="container pt-5 pb-5">

        <div className="row">
          <div className="col-md-9 order-md-2">
            <div className="card card-calcular-precio">
              <div className="card-body">
                <h4 className="font-weight-bold">Formulario de contacto</h4>
                <hr />
                <Form />
              </div>
            </div>
          </div>

          <div className="col-md-3 order-md-1 text-center p-5 p-md-0 pt-md-4">
            <ContactoLeft />
          </div>
        </div>

      </div>
    )
  }
}
