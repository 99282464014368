import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'

import Cookies from './../Cookies'
import ContactButtons from './../ContactButtons'
import MainLinks from './../MainLinks'
import ModalMenu from './ModalMenu'
import Tel788 from './../Telefonos/788'

import ScrollTo from './../../services/scroll-to'


export default class Nav extends PureComponent {
  state = {
    modalMenuShown: false,
  }

  componentDidMount () {
    ScrollTo.top()
  }

  openModalMenu = () => {
    this.setState({
      modalMenuShown: true,
    })
  }

  closeModalMenu = () => {
    this.setState({
      modalMenuShown: false,
    })
  }

  render () {
    const { modalMenuShown } = this.state

    return (
      <>
        <ModalMenu
          shown={modalMenuShown}
          onCloseModalMenu={this.closeModalMenu}
        />
        <Cookies />
        <ContactButtons />
        <header>
          <div className="mini-bar">
            <div className="container d-flex justify-content-between">
              <span>
                <a href="http://llort-tarradas.com" target="_blank" rel="noopener noreferrer">
                  <span className="d-none d-md-inline">
                    Grupo {' '}
                  </span>
                  <strong>
                      Llort-Tarradas
                  </strong>
                </a>
              </span>

              <div className="d-flex justify-content-end">
                <span>
                  <a
                    href="mailto:info@segurosnauticos.es"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="mr-1" style={{ position: 'relative', top: '-1px' }}>
                      <svg
                        className="bi bi-envelope-open"
                        width="1em"
                        height="1em"
                        viewBox="0 0 16 16"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path fillRule="evenodd" d="M.243 6.929l.514-.858L8 10.417l7.243-4.346.514.858L8 11.583.243 6.93z" clipRule="evenodd"/>
                        <path fillRule="evenodd" d="M7.184 10.68L.752 14.432l-.504-.864L6.68 9.816l.504.864zm1.632 0l6.432 3.752.504-.864L9.32 9.816l-.504.864z" clipRule="evenodd"/>
                        <path fillRule="evenodd" d="M8.47 1.318a1 1 0 00-.94 0l-6 3.2A1 1 0 001 5.4V14a1 1 0 001 1h12a1 1 0 001-1V5.4a1 1 0 00-.53-.882l-6-3.2zM7.06.435a2 2 0 011.882 0l6 3.2A2 2 0 0116 5.4V14a2 2 0 01-2 2H2a2 2 0 01-2-2V5.4a2 2 0 011.059-1.765l6-3.2z" clipRule="evenodd"/>
                      </svg>
                    </span>
                    <strong
                      className="d-none d-md-inline"
                    >
                      info@segurosnauticos.es
                    </strong>
                  </a>

                  <span className="pl-2" >-</span>

                  <span className="pl-2">
                    <Tel788>
                      <svg className="bi bi-phone" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M11 1H5a1 1 0 00-1 1v12a1 1 0 001 1h6a1 1 0 001-1V2a1 1 0 00-1-1zM5 0a2 2 0 00-2 2v12a2 2 0 002 2h6a2 2 0 002-2V2a2 2 0 00-2-2H5z" clipRule="evenodd"/>
                        <path fillRule="evenodd" d="M8 14a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd"/>
                      </svg>
                      <strong
                        className="d-none d-md-inline"
                      >
                        +34 629 348 788
                      </strong>
                    </Tel788>
                  </span>
                </span>
              </div>
            </div>
          </div>

          <div className="main-bar">
            <div className="container d-flex justify-content-between align-content-center">
              <span>
                <Link className="navbar-brand mr-4" to="/">
                  <img src="/resources/img/logo.svg" height="48px" />
                  {/* <img src="/resources/img/logo.png" /> */}
                </Link>
              </span>
              <div className="d-flex d-lg-none justify-content-end navbar-light">
                <button
                  onClick={this.openModalMenu}
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
              </div>
              <div className="d-none d-lg-flex justify-content-end">
                <MainLinks />
              </div>
            </div>
          </div>
        </header>
      </>
    )
  }
}
