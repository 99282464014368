const GetPrice = {
  offerOptionNames: [
    'BÁSICA',
    'POPULAR',
    'COMPLETA',
    '+ACCIDENTES',
  ],

  includesOptions: [
    'RC Obligatoria',
    'Remolque marítimo + Rescate',
    'Defensa jurídica + Reclamación de daños',
    'Asistencia',
    'Remoción de restos',
    'Accidentes',
  ],

  tarifasMoto: {
    plazas2: {
      potencia50: [
        64.20,
        93.58,
        105.05,
        128.04,
      ],
      potencia100: [
        74.23,
        103.62,
        115.08,
        138.08,
      ],
      potencia150: [
        86.27,
        115.65,
        127.12,
        150.11,
      ],
      potencia200: [
        103.33,
        137.71,
        149.18,
        172.18,
      ],
      potencia250: [
        113.36,
        147.75,
        159.21,
        182.22,
      ],
      potencia300: [
        138.43,
        172.82,
        184.29,
        207.28,
      ],
    },
    plazas3: {
      potencia50: [
        73.37,
        102.76,
        114.22,
        148.72,
      ],
      potencia100: [
        84.84,
        114.22,
        125.68,
        160.19,
      ],
      potencia150: [
        98.59,
        127.97,
        139.44,
        173.95,
      ],
      potencia200: [
        118.08,
        152.48,
        163.94,
        198.44,
      ],
      potencia250: [
        129.54,
        163.94,
        175.40,
        209.91,
      ],
      potencia300: [
        158.20,
        192.60,
        204.06,
        238.57,
      ],
    },
  },

  tarifasNeumaticas: {
    potencia50: [
      47.77,
      72.61,
      90.29,
    ],
    potencia100: [
      56.64,
      82.92,
      103.46,
    ],
    potencia150: [
      66.93,
      93.21,
      113.75,
    ],
    potencia200: [
      92.69,
      123.73,
      146.67,
    ],
    potencia250: [
      128.74,
      159.79,
      182.72,
    ],
    potencia300: [
      154.48,
      185.53,
      211.32,
    ],
  },

  tarifasRigidas: {
    potencia50: [
      47.77,
      68.41,
      86.08,
    ],
    potencia100: [
      51.49,
      77.77,
      98.31,
    ],
    potencia150: [
      60.86,
      87.12,
      107.67,
    ],
    potencia200: [
      84.26,
      115.31,
      138.24,
    ],
    potencia250: [
      117.04,
      148.08,
      171.01,
    ],
    potencia300: [
      140.44,
      171.48,
      197.28,
    ],
  },

  getOfertasMotos (numPlazas, potencia, esquiAcuatico) {
    return [
      {
        name: this.offerOptionNames[0],
        price: (
          this.tarifasMoto[`plazas${numPlazas}`][`potencia${potencia}`][0]
        ),
        includes: [
          this.includesOptions[0],
        ],
      },
      {
        name: this.offerOptionNames[1],
        price: (
          this.tarifasMoto[`plazas${numPlazas}`][`potencia${potencia}`][1]
        ),
        includes: [
          this.includesOptions[0],
          this.includesOptions[1],
          this.includesOptions[2],
        ],
      },
      {
        name: this.offerOptionNames[2],
        price: (
          this.tarifasMoto[`plazas${numPlazas}`][`potencia${potencia}`][2]
        ),
        includes: [
          this.includesOptions[0],
          this.includesOptions[1],
          this.includesOptions[2],
          this.includesOptions[4],
        ],
      },
      {
        name: this.offerOptionNames[3],
        price: esquiAcuatico ? undefined : this.tarifasMoto[`plazas${numPlazas}`][`potencia${potencia}`][3],
        includes: [
          this.includesOptions[0],
          this.includesOptions[1],
          this.includesOptions[2],
          this.includesOptions[4],
          this.includesOptions[5],
        ],
      },
    ]
  },

  getOfertasNeumaticas (potencia) {
    return [
      {
        name: this.offerOptionNames[0],
        price: (
          this.tarifasNeumaticas[`potencia${potencia}`][0]
        ),
        includes: [
          this.includesOptions[0],
        ],
      },
      {
        name: this.offerOptionNames[1],
        price: (
          this.tarifasNeumaticas[`potencia${potencia}`][1]
        ),
        includes: [
          this.includesOptions[0],
          this.includesOptions[1],
          this.includesOptions[2],
        ],
      },
      {
        name: this.offerOptionNames[2],
        price: (
          this.tarifasNeumaticas[`potencia${potencia}`][2]
        ),
        includes: [
          this.includesOptions[0],
          this.includesOptions[1],
          this.includesOptions[2],
          this.includesOptions[3],
          this.includesOptions[4],
        ],
      },
      {
        name: this.offerOptionNames[3],
        price: undefined,
        includes: [
          this.includesOptions[0],
          this.includesOptions[1],
          this.includesOptions[2],
          this.includesOptions[3],
          this.includesOptions[4],
          this.includesOptions[5],
        ],
      },
    ]
  },

  getOfertasRigidas (potencia) {
    return [
      {
        name: this.offerOptionNames[0],
        price: (
          this.tarifasRigidas[`potencia${potencia}`][0]
        ),
        includes: [
          this.includesOptions[0],
        ],
      },
      {
        name: this.offerOptionNames[1],
        price: (
          this.tarifasRigidas[`potencia${potencia}`][1]
        ),
        includes: [
          this.includesOptions[0],
          this.includesOptions[1],
          this.includesOptions[2],
        ],
      },
      {
        name: this.offerOptionNames[2],
        price: (
          this.tarifasRigidas[`potencia${potencia}`][2]
        ),
        includes: [
          this.includesOptions[0],
          this.includesOptions[1],
          this.includesOptions[2],
          this.includesOptions[3],
          this.includesOptions[4],
        ],
      },
      {
        name: this.offerOptionNames[3],
        price: undefined,
        includes: [
          this.includesOptions[0],
          this.includesOptions[1],
          this.includesOptions[2],
          this.includesOptions[3],
          this.includesOptions[4],
          this.includesOptions[5],
        ],
      },
    ]
  },

  getPrices (formData) {
    const {
      tipo, subtipoMotor, numPlazas, numMotores, potencia, eslora,
      bandera, uso, area, esquiAcuatico,
    } = formData

    const intPotencia = +(potencia)
    const intNumPlazas = +(numPlazas)
    const intNumMotores = +(numMotores)

    let intEslora = +((eslora || '').replace(',', '.'))
    if (intEslora > 100) {
      intEslora = intEslora / 100
    }

    if (bandera !== '64' && area !== '1' && uso !== '1') {
      return
    }

    if (tipo === 'Moto') {
      if (intNumPlazas === 2 || intNumPlazas === 3) {
        if (intPotencia <= 50) {
          return this.getOfertasMotos(numPlazas, 50, esquiAcuatico)
        } else if (intPotencia > 50 && intPotencia <= 100) {
          return this.getOfertasMotos(numPlazas, 100, esquiAcuatico)
        } else if (intPotencia > 100 && intPotencia <= 150) {
          return this.getOfertasMotos(numPlazas, 150, esquiAcuatico)
        } else if (intPotencia > 150 && intPotencia <= 200) {
          return this.getOfertasMotos(numPlazas, 200, esquiAcuatico)
        } else if (intPotencia > 200 && intPotencia <= 250) {
          return this.getOfertasMotos(numPlazas, 250, esquiAcuatico)
        } else if (intPotencia > 250 && intPotencia <= 300) {
          return this.getOfertasMotos(numPlazas, 300, esquiAcuatico)
        }
      }
    } else if (tipo === 'Motor') {
      if (intEslora > 6) {
        return
      }

      if (intNumMotores > 2) {
        return
      }

      if (subtipoMotor === 'Neumatica' || subtipoMotor === 'Semirigida') {
        if (intPotencia <= 50) {
          return this.getOfertasNeumaticas(50)
        } else if (intPotencia > 50 && intPotencia <= 100) {
          return this.getOfertasNeumaticas(100)
        } else if (intPotencia > 100 && intPotencia <= 150) {
          return this.getOfertasNeumaticas(150)
        } else if (intPotencia > 150 && intPotencia <= 200) {
          return this.getOfertasNeumaticas(200)
        } else if (intPotencia > 200 && intPotencia <= 250) {
          return this.getOfertasNeumaticas(250)
        } else if (intPotencia > 250 && intPotencia <= 300) {
          return this.getOfertasNeumaticas(300)
        }
      } else if (subtipoMotor === 'Rigida') {
        if (intPotencia <= 50) {
          return this.getOfertasRigidas(50)
        } else if (intPotencia > 50 && intPotencia <= 100) {
          return this.getOfertasRigidas(100)
        } else if (intPotencia > 100 && intPotencia <= 150) {
          return this.getOfertasRigidas(150)
        } else if (intPotencia > 150 && intPotencia <= 200) {
          return this.getOfertasRigidas(200)
        } else if (intPotencia > 200 && intPotencia <= 250) {
          return this.getOfertasRigidas(250)
        } else if (intPotencia > 250 && intPotencia <= 300) {
          return this.getOfertasRigidas(300)
        }
      }
    }
  },
}

export default GetPrice
