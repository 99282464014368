import React from 'react'
import { observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'

import withNavbar from '../../ui/withNavbar'
import Store from './../../../mobx'
import Component from './index'

const { policy, offers } = Store

const Wrap = withRouter(withNavbar(Component))

export default observer(props => (
  <Wrap
    {...props}

    policy={policy.policy}
    selectedOffer={offers.selectedOffer}
  />
))
