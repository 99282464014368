import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import FAQ from './FAQ'


export default class Home extends Component {
  render () {
    return (
      <>
        <div className="jumbo">
          <div className="overlay"></div>
          <video playsinline="playsinline" autoPlay="autoplay" muted="muted" loop="loop" id="bck-video">
            <source src="/resources/video/sea.mp4" type="video/mp4" />
          </video>
          <div className="container h-100">
            <div className="d-flex h-100 text-center align-items-center">
              <div className="w-100 text-white">
                <h1 className="display-3">Expertos en el mar</h1>

                <p className="h3">Contrata tu seguro online</p>

                <br />

                <div className="d-flex justify-content-center">
                  <Link
                    className="btn btn-primary btn-lg"
                    to="/calcular-precio"
                  >
                    CALCULAR PRECIO
                  </Link>
                  {/* <div className="btn btn-outline-primary btn-lg ml-4">TE LLAMAMOS</div> */}
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="container pt-5">
          <div className="row">
            <div className="col-lg-3 col-sm-6">
              <div
                className="service-img"
                style={{
                  backgroundImage: 'url(/resources/img/motor.jpg)',
                }}
              >
                <h2>Embarcaciones<br /> motor</h2>

                <br />
                <br />
                <Link
                  className="btn btn-primary"
                  to="/calcular-precio?tipo=Motor"
                >
                  CALCULAR PRECIO
                </Link>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div
                className="service-img"
                style={{
                  backgroundImage: 'url(/resources/img/vela.jpg)',
                }}
              >
                <h2>Embarcaciones<br /> a vela</h2>

                <br />
                <br />
                <Link
                  className="btn btn-primary"
                  to="/calcular-precio?tipo=Vela"
                >
                  CALCULAR PRECIO
                </Link>
              </div>
            </div>

            <div className="col-lg-3 col-sm-6">
              <div
                className="service-img"
                style={{
                  backgroundImage: 'url(/resources/img/neumatica.jpg)',
                }}
              >
                <h2>Embarcaciones<br /> neumáticas</h2>

                <br />
                <br />
                <Link
                  className="btn btn-primary"
                  to="/calcular-precio?tipo=Neumatica"
                >
                  CALCULAR PRECIO
                </Link>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div
                className="service-img"
                style={{
                  backgroundImage: 'url(/resources/img/moto-agua.jpg)',
                }}
              >
                <h2>Motos <br /> de agua</h2>

                <br />
                <br />
                <Link
                  className="btn btn-primary"
                  to="/calcular-precio?tipo=Moto"
                >
                  CALCULAR PRECIO
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="container p-5">
          <hr />
        </div>


        <div>
          <div className="container">

            <div className="row">
              <div className="col-md-4">
                <div className="card p-4 mb-3">
                  <h2 className="squada text-center color2">
                    Adaptado <br /> a ti
                  </h2>
                  <p>
                    Tienes la opción de poder elegir en todo momento como quieres asegurar tu embarcación.
                    <br className="d-none d-md-block" />
                    <br className="d-none d-md-block" />
                    <br className="d-none d-md-block" />
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card p-4 mb-3">
                  <h2 className="squada  text-center color2">
                    Asistencia <br /> marítima
                  </h2>
                  <p>
                    ¿Tienes algún problema con tu embarcación? No te preocupes, contrata el servicio de asistencia y remolque marítimo. Te asegurarás la tranquilidad ante los imprevistos.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card p-4 mb-3">
                  <h2 className="squada text-center color2">
                    Posibilidad de ampliar <br /> las coberturas
                  </h2>
                  <p>
                    Opción de ampliar las coberturas de responsabilidad civil o accidentes de los ocupantes, entre otras, para así viajar con aún más tranquilidad.
                    <br className="d-none d-md-block" />
                    <br className="d-none d-md-block" />
                  </p>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div className="container p-5">
          <hr />
        </div>

        <div className="container">

          <h1 className="squada text-center pb-4">
            PREGUNTAS FRECUENTES
          </h1>

          <FAQ />

        </div>
      </>
    )
  }
}
