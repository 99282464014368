import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'

class NotFound extends PureComponent {
  render () {
    return (
      <div>
        <div className="container pt-5 pb-5">

          <h1>Página no encontrada</h1>

          <br className="p-5" />
          <br className="p-5" />

          <h3>
            <Link to="/">
              Volver a la página de inicio {'>'}
            </Link>
          </h3>

          <br className="p-5" />
          <br className="p-5" />
          <br className="p-5" />
          <br className="p-5" />
          <br className="p-5" />

        </div>
      </div>
    )
  }
}

export default NotFound
