import React, { Component } from 'react'

import ContactoLeft from './../../ContactoLeft'

export default class QuienesSomos extends Component {
  render () {
    const years = new Date().getFullYear() - 2004

    return (

      <div>
        <div className="container pt-5">

          <div className="row">
            <div className="col-md-9 order-md-2">

              <div className="card">
                <div className="card-body">
                  <h4 className="font-weight-bold">Seguros nauticos</h4>
                  <hr />
                  <p>
                    <strong>Segurosnauticos.es</strong> es propiedad de <strong>Grupo Llort Tarradas S.L.</strong>{' '}
                    creado exclusivamente para el seguro náutico.
                  </p>
                  <br />
                  <h4 className="font-weight-bold">Pasión por el mar</h4>
                  <hr />
                  <p>
                    Nuestra pasión por el mar y el conocimiento técnico tanto de las embarcaciones como del
                    producto que le ofrecemos avala la confianza que le agradecemos deposite en nosotros.
                  </p>
                  <br />
                  <h4 className="font-weight-bold">Nos avalan {years} años</h4>
                  <hr />
                  <p>
                    Llort Tarradas S.L., con <strong>{years} años de experiencia</strong> en el sector de seguros, está altamente
                    especializado en grandes riesgos además de una importante presencia en el sector
                    del ocio con más de 4.000 asegurados propietarios de caravanas, auto-caravanas, mobil-homes y
                    embarcaciones.
                  </p>
                  <p>
                    El portal www.segurosnauticos.es se ha creado con la finalidad de poner al alcance de los
                    armadores de pequeñas y medianas embarcaciones el mejor asesoramiento y oferta ajustada
                    a las necesidades de cada propietario y embarcación.
                  </p>
                </div>
              </div>

            </div>
            <div className="col-md-3 order-md-1 text-center p-5 p-md-0 pt-md-4">
              <ContactoLeft />
            </div>
          </div>

        </div>
      </div>

    )
  }
}
