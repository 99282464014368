import React, { Component } from 'react'

import Concept from './../../ofertas/Concept'

class OfertaContratada extends Component {
  render () {
    const { selectedOffer } = this.props
    const { name, includes, price } = selectedOffer

    return (
      <div className="card">
        <div className="card-header">
          <span>Oferta seleccionada:</span>
        </div>
        <div className="card-body">
          <h2 className="d-flex justify-content-between">
            <span className="squada">
              {name}
            </span>
            <small className="text-muted"> ({price} €)</small>
          </h2>
          <hr />
          {includes.map(include => (
            <Concept
              key={include}
              concept={include}
              small
            />
          ))}
        </div>
      </div>
    )
  }
}

export default OfertaContratada
